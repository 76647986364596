import React, { useEffect, useState } from "react";
import { db } from "../config/firebase-config";
import { doc, setDoc } from "firebase/firestore";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

import StatusColumn from "./StatusColumn";
import ModalFahrzeug from "./ModalFahrzeug";

export const Overview = (props) => {
  const [fahrzeuge, setFahrzeuge] = useState([]);
  const [fahrzeugeSort, setFahrzeugeSort] = useState([]);
  const [funkkennungen, setFunkkennungen] = useState([]);
  const [activeID, setActiveID] = useState("");
  const [activeFahrzeug, setActiveFahrzeug] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalFunkkennung, setModalFunkkennung] = useState("");
  const [modalStandort, setModalStandort] = useState("");
  const [modalBemerkung, setModalBemerkung] = useState("");
  const [dragItem, setDragItem] = useState({
    dragging: true,
    id: "",
    column: "",
    target: "",
    targetStore: "",
  });
  const [desDate, setDesDate] = useState(new Date());

  const columns = [
    {
      bg: "#7fc865",
      color: "",
      column: "einsatzbereit",
      title: "Regelbedarf",
    },
    {
      bg: "#f8e43a",
      color: "",
      column: "taktischeReserve",
      title: "Spitzenbedarf",
    },
    {
      bg: "#e89042",
      color: "",
      column: "technischeReserve",
      title: "Reservefahrzeuge",
    },
    {
      bg: "#112e4b",
      color: "white",
      column: "sonderbedarf",
      title: "Sonderbedarf",
    },
    {
      bg: "#1b5084",
      color: "white",
      column: "fuehrung",
      title: "Führung",
    },
    {
      bg: "#d72020",
      color: "white",
      column: "ausserdienst",
      title: "Nicht Dienstfähig",
    },
    {
      bg: "#2987e7",
      color: "white",
      column: "desinfektion",
      title: "Desinfektion",
    },
  ];

  // const fahrzeugeRef = collection(db, "fahrzeuge");
  // const funkkennungenRef = collection(db, "funkkennungen");

  useEffect(() => {
    props.setSearchString("");
  }, []);

  useEffect(() => {
    // const queryFahrzeuge = query(fahrzeugeRef, orderBy("funkkennung"));

    // const unsubscribe = onSnapshot(queryFahrzeuge, (snapshot) => {
    //   let fahrzeuge = [];
    //   snapshot.forEach((doc) => {
    //     fahrzeuge.push({ ...doc.data(), id: doc.id });
    //   });
    //   setFahrzeuge(fahrzeuge);
    // });

    // const queryFunkkennungen = query(funkkennungenRef, orderBy("bezeichnung"));

    // const unsubscribe2 = onSnapshot(queryFunkkennungen, (snapshot) => {
    //   let funkkennungen = [];
    //   snapshot.forEach((doc) => {
    //     funkkennungen.push({ ...doc.data(), id: doc.id });
    //   });
    //   setFunkkennungen(funkkennungen);
    // });

    setFahrzeuge(props.fahrzeuge);
    setFunkkennungen(props.funkkennungen);
  }, [props.fahrzeuge, props.funkkennungen]);

  useEffect(() => {
    const filterFahrzeuge = (fahrzeug) => {
      return (
        fahrzeug.funkkennung
          .toLowerCase()
          .includes(props.searchString.toLowerCase()) ||
        fahrzeug.kennzeichen
          .toLowerCase()
          .includes(props.searchString.toLowerCase()) ||
        fahrzeug.standort
          .toLowerCase()
          .includes(props.searchString.toLowerCase()) ||
        fahrzeug.bemerkung
          .toLowerCase()
          .includes(props.searchString.toLowerCase())
      );
    };

    let fahrzeugeSort = [];

    fahrzeuge.forEach((element) => {
      fahrzeugeSort.push(element);
      if (element.id === activeID) {
        setModalFunkkennung(element.funkkennung);
      }
    });

    fahrzeugeSort.sort((a, b) => {
      return a.kennzeichen.localeCompare(b.kennzeichen);
    });

    fahrzeugeSort.sort((a, b) => {
      if (a.funkkennung === "") return 1;
      if (b.funkkennung === "") return -1;
      return a.funkkennung.localeCompare(b.funkkennung);
    });

    // Filtern der Fahrzeuge mit searchString
    if (props.searchString !== "") {
      fahrzeugeSort = fahrzeugeSort.filter(filterFahrzeuge);
    }

    setFahrzeugeSort(fahrzeugeSort);
  }, [fahrzeuge, activeID, props.searchString]);

  const dragStart = (e, id, column) => {
    // console.log("dragStart", dragItem);
    e.target.classList.add("dragging");
    setDragItem({
      dragging: true,
      id,
      column,
      target: "",
      targetStore: "",
    });
  };

  const dragEnter = (e, target) => {
    if (dragItem.target !== target && dragItem.column !== target) {
      const tmpDragItem = {
        dragging: dragItem.dragging,
        id: dragItem.id,
        column: dragItem.column,
        target,
        targetStore: target,
      };
      setDragItem(tmpDragItem);
    }
  };

  const dragEnd = (e, id, column) => {
    // console.log('dragEnd (Overview.js)', dragItem, id);
    e.target.classList.remove("dragging");

    if (dragItem.target === "") return false;

    handleOpenModal(id);

    // Update collection Fahrzeuge
    // Fahrzeug mit der ID bekommt aktuellen Status + Anpassungen
    // console.log('Update Fahrzeug in DB:', dragItem.id, dragItem.target);

    // const docRef = doc(db, "fahrzeuge", dragItem.id);

    // const data = {
    //   status: dragItem.target,
    // };

    // setDoc(docRef, data, { merge: true })
    //   .then((docRef) => {
    //     handleOpenModal(id);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // setTimeout(() => {
    //   setDragItem({
    //     dragging: true,
    //     id,
    //     column,
    //     target: "",
    //   });
    // }, 50);
  };

  const handleOpenModal = (id) => {
    setActiveID(id);
    setDragItem({
      dragging: false,
      id: dragItem.id,
      column: dragItem.column,
      target: "",
      targetStore: dragItem.targetStore,
    });
    // getDataById(id);

    // Infos für Modal laden
    fahrzeuge.forEach((element) => {
      if (element.id === id) {
        setActiveFahrzeug(element);
        setModalFunkkennung(element.funkkennung);
        setModalStandort(element.standort);
        setModalBemerkung(element.bemerkung);
      }
    });

    setShowModal(true);
    // setTimeout(() => setShowModal(true), 500);
  };

  // const getDataById = async (id) => {
  //   const docRef = doc(db, "fahrzeuge", id);
  //   try {
  //     const docSnap = await getDoc(docRef);
  //     setActiveFahrzeug(docSnap.data());
  //     setModalFunkkennung(docSnap.data().funkkennung);
  //     setModalStandort(docSnap.data().standort);
  //     setModalBemerkung(docSnap.data().bemerkung);
  //     // console.log(docSnap.data());
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const closeModal = () => {
    setShowModal(false);
    setDragItem({
      dragging: true,
      id: "",
      column: "",
      target: "",
      targetStore: "",
    });
  };

  const updateFunkkennung = (funkkennungOld, FunkkennungNew) => {
    let inUseId = "";
    let notInUseId = "";

    if (funkkennungOld === FunkkennungNew) return false;

    for (let i = 0; i < funkkennungen.length; i++) {
      if (funkkennungOld === funkkennungen[i].bezeichnung)
        notInUseId = funkkennungen[i].id;
      if (FunkkennungNew === funkkennungen[i].bezeichnung)
        inUseId = funkkennungen[i].id;
    }

    if (inUseId !== "") {
      const docRef = doc(db, "funkkennungen", inUseId);

      const data = {
        inUse: true,
      };

      setDoc(docRef, data, { merge: true })
        .then((docRef) => {
          // console.log('Update Funkkennungen')
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (notInUseId !== "") {
      const docRef2 = doc(db, "funkkennungen", notInUseId);

      const data2 = {
        inUse: false,
      };

      setDoc(docRef2, data2, { merge: true })
        .then((docRef) => {
          // console.log('Update Funkkennungen')
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // console.log(notInUseId, inUseId);
  };

  return (
    <>
      <ModalFahrzeug
        id={activeID}
        fahrzeug={activeFahrzeug}
        fahrzeuge={fahrzeuge}
        funkkennung={modalFunkkennung}
        funkkennungen={funkkennungen}
        updateFunkkennung={updateFunkkennung}
        standort={modalStandort}
        setStandort={setModalStandort}
        bemerkung={modalBemerkung}
        setBemerkung={setModalBemerkung}
        showModal={showModal}
        closeModal={closeModal}
        target={dragItem.targetStore}
        desDate={desDate}
        setDesDate={setDesDate}
      />
      <Container fluid>
        <Row>
          {/* <div>
            dragging: {dragItem.dragging ? "true" : "false"}
            <br />
            id: {dragItem.id}
            <br />
            column: {dragItem.column}
            <br />
            target: {dragItem.target}
            <br />
            targetStore: {dragItem.targetStore}
          </div> */}
          {columns.map((column) => {
            return (
              <Col key={column.column}>
                <StatusColumn
                  title={column.title}
                  bg={column.bg}
                  color={column.color}
                  column={column.column}
                  data={fahrzeugeSort}
                  dragItem={dragItem}
                  dragEnter={dragEnter}
                  dragStart={dragStart}
                  dragEnd={dragEnd}
                  handleOpenModal={handleOpenModal}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default Overview;
