import React, { useState, useEffect } from "react";
import { db, auth } from "../config/firebase-config";
import {
  collection,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  where,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";

import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { MdDeleteForever, MdAddCircle } from "react-icons/md";
import { BsFillPencilFill } from "react-icons/bs";

const EinstellungenFahrzeuge = (props) => {
  const [fahrzeuge, setFahrzeuge] = useState([]);
  const [fahrzeugKennzeichen, setFahrzeugeKennzeichen] = useState("");
  const [fahrzeugNutzer, setFahrzeugeNutzer] = useState("");
  const [fahrzeugPowerload, setFahrzeugPowerload] = useState(false);
  const [fahrzeugID, setFahrzeugID] = useState("");
  const [modal, setModal] = useState(false);

  const fahrzeugeRef = collection(db, "fahrzeuge");

  useEffect(() => {
    const queryFahrzeuge = query(fahrzeugeRef, orderBy("kennzeichen"));

    const unsubscribe = onSnapshot(queryFahrzeuge, (snapshot) => {
      let fahrzeuge = [];
      snapshot.forEach((doc) => {
        fahrzeuge.push({ ...doc.data(), id: doc.id });
      });
      setFahrzeuge(fahrzeuge);
      // console.log("fahrezuege aus db", fahrzeuge);
    });

    return () => unsubscribe();
  }, []);

  const handleClose = () => {
    setModal(false);
    setFahrzeugeKennzeichen("");
    setFahrzeugeNutzer("");
    setFahrzeugPowerload(false);
    setFahrzeugID("");
  };

  const handleOpen = () => {
    setModal(true);
  };

  const handleSave = () => {
    const data = {
      bemerkung: "",
      funkkennung: "",
      kennzeichen: fahrzeugKennzeichen,
      nutzer: fahrzeugNutzer,
      powerload: fahrzeugPowerload,
      standort: "",
      status: "ausserdienst",
    };

    // Fahrzeug neu anlegen oder bestehendes Anpassen

    if (fahrzeugID === "") {
      addDoc(fahrzeugeRef, data)
        .then((docRef) => {
          handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const dataUpdate = {
        kennzeichen: fahrzeugKennzeichen,
        nutzer: fahrzeugNutzer,
        powerload: fahrzeugPowerload,
      };
      data.id = fahrzeugID;
      // console.log('data update', dataUpdate);

      const docRef = doc(db, "fahrzeuge", data.id);

      setDoc(docRef, dataUpdate, { merge: true })
        .then((docRef) => {
          // console.log("Document Field has been updated successfully");
          handleClose();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleDelete = (id) => {
    // console.log('delete clicked', id);
    const docRef = doc(db, "fahrzeuge", id);
    deleteDoc(docRef)
      .then(() => {
        // console.log('Eintrag gelöscht');
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const handleEdit = (fahrzeug) => {
    // console.log('handleEdit', fahrzeug);
    setFahrzeugID(fahrzeug.id);
    setFahrzeugeKennzeichen(fahrzeug.kennzeichen);
    setFahrzeugeNutzer(fahrzeug.nutzer);
    setFahrzeugPowerload(fahrzeug.powerload);
    setModal(true);
  };

  let color = "black";
  if (props.color !== undefined) color = props.color;
  return (
    <>
      <Card className="statusColumn">
        <Card.Header
          style={{
            backgroundColor: props.bg,
            color: color,
            textAlign: "center",
          }}
        >
          <div className="settingHeader">
            <div className="settingTitle">{props.title}</div>
            <div>
              <Button
                variant="success"
                className="btn-add"
                onClick={handleOpen}
              >
                <MdAddCircle />
              </Button>
            </div>
          </div>
        </Card.Header>

        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nutzungsart</th>
                <th>Kennzeichen</th>
                <th>Funkkennung</th>
                <th>Standort</th>
                <th>Aktion</th>
              </tr>
            </thead>
            <tbody>
              {fahrzeuge.map((fahrzeug) => {
                return (
                  <tr key={fahrzeug.id}>
                    <td>{fahrzeug.nutzer}</td>
                    <td>{fahrzeug.kennzeichen}</td>
                    <td>{fahrzeug.funkkennung}</td>
                    <td>{fahrzeug.standort}</td>
                    <td>
                      <BsFillPencilFill
                        className="btn-edit"
                        onClick={() => {
                          handleEdit(fahrzeug);
                        }}
                      />
                      <MdDeleteForever
                        className="btn-delete"
                        onClick={() => {
                          handleDelete(fahrzeug.id);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={modal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Fahrzeug hinzufügen</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Kennzeichen</Form.Label>
            <Form.Control
              type="text"
              value={fahrzeugKennzeichen}
              onChange={(event) => setFahrzeugeKennzeichen(event.target.value)}
              placeholder="Kennzeichen eingeben"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Nutzungsart</Form.Label>
            <Form.Control
              type="text"
              value={fahrzeugNutzer}
              onChange={(event) => setFahrzeugeNutzer(event.target.value)}
              placeholder="Nutzungsart eingeben"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Powerload</Form.Label>
            <Form.Select
              value={fahrzeugPowerload}
              onChange={(event) => setFahrzeugPowerload(event.target.value)}
            >
              <option value={false}>Kein Powerload vorhanden</option>
              <option value={true}>Powerload vorhanden</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EinstellungenFahrzeuge;
