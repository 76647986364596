import React from 'react';

import Card from 'react-bootstrap/Card';

const DisplayBoxPlaceholder = (props) => {

  return (
    <Card className="displayBoxPlaceholder">
      +
    </Card>
  );
}

export default DisplayBoxPlaceholder;