import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

const MsgBox = (props) => {
  let classNames = "msgbox";
  let typeClass = "msg-error";

  if (props.type === "info") typeClass = "msg-info";

  classNames += " " + typeClass;
  return <div className={classNames}>{props.msg}</div>;
};

export default MsgBox;
