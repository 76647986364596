import React from "react";

import Card from "react-bootstrap/Card";

import MangelBox from "./MangelBox";
import DisplayBoxPlaceholder from "./DisplayBoxPlaceholder";

const MangelColumn = (props) => {
  let color = "black";
  if (props.color !== undefined) color = props.color;
  return (
    <Card className="statusColumn">
      <Card.Header
        style={{ backgroundColor: props.bg, color: color, textAlign: "center" }}
      >
        {props.title}
      </Card.Header>
      <Card.Footer className="statusFooter">
        {props.maengel.length === 0 ? "Keine Mängel" : undefined}
        {props.maengel.length === 1 ? "1 Mangel" : undefined}
        {props.maengel.length > 1
          ? props.maengel.length + " Mängel"
          : undefined}
      </Card.Footer>
      <Card.Body>
        {props.maengel.map((mangel) => {
          return (
            <MangelBox
              date={mangel.date}
              melder={mangel.melder}
              prio={mangel.prio}
            />
          );
        })}
      </Card.Body>
    </Card>
  );
};

export default MangelColumn;
