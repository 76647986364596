import React, { useState, useEffect } from "react";
import { db, auth } from "../config/firebase-config";
import {
  collection,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  where,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";

import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { MdDeleteForever, MdAddCircle } from "react-icons/md";

const EinstellungenStandorte = (props) => {
  const [standorte, setStandorte] = useState([]);
  const [standortNeu, setStandortNeu] = useState("");
  const [standortTypen, setStandortTypen] = useState([
    "Dienstort",
    "Leistungscenter",
  ]);
  const [standortTyp, setStandortTyp] = useState("Dienstort");
  const [modal, setModal] = useState(false);

  const standorteRef = collection(db, "standorte");

  useEffect(() => {
    const queryStandorte = query(standorteRef, orderBy("bezeichnung"));

    const unsubscribe = onSnapshot(queryStandorte, (snapshot) => {
      let standorte = [];
      snapshot.forEach((doc) => {
        standorte.push({ ...doc.data(), id: doc.id });
      });
      setStandorte(standorte);
    });

    return () => unsubscribe();
  }, []);

  const handleClose = () => {
    setModal(false);
    setStandortNeu("");
    setStandortTyp("Dienstort");
  };

  const handleOpen = () => {
    setModal(true);
  };

  const handleSave = () => {
    const data = {
      bezeichnung: standortNeu,
      typ: standortTyp,
    };

    addDoc(standorteRef, data)
      .then((docRef) => {
        // console.log("Document Field has been updated successfully");
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    // console.log('delete clicked', id);
    const docRef = doc(db, "standorte", id);
    deleteDoc(docRef)
      .then(() => {
        // console.log('Eintrag gelöscht');
      })
      .catch((error) => {
        console.log("error");
      });
  };

  let color = "black";
  if (props.color !== undefined) color = props.color;
  return (
    <>
      <Card className="statusColumn">
        <Card.Header
          style={{
            backgroundColor: props.bg,
            color: color,
            textAlign: "center",
          }}
        >
          <div className="settingHeader">
            <div className="settingTitle">{props.title}</div>
            <div>
              <Button
                variant="success"
                className="btn-add"
                onClick={handleOpen}
              >
                <MdAddCircle />
              </Button>
            </div>
          </div>
        </Card.Header>

        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Bezeichnung</th>
                <th>Aktion</th>
              </tr>
            </thead>
            <tbody>
              {standorte.map((standort) => {
                return (
                  <tr key={standort.id}>
                    <td>
                      {standort.bezeichnung} <br /> {standort.typ}
                    </td>
                    <td>
                      <MdDeleteForever
                        className="btn-delete"
                        onClick={() => {
                          handleDelete(standort.id);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={modal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Standort hinzufügen</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Bezeichnung</Form.Label>
            <Form.Control
              type="text"
              value={standortNeu}
              onChange={(event) => setStandortNeu(event.target.value)}
              placeholder="Bezeichnung eingeben"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Funkkennung</Form.Label>
            <Form.Select
              value={standortTyp}
              name="modalStandortTyp"
              onChange={(e) => {
                setStandortTyp(e.target.value);
              }}
            >
              {standortTypen.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EinstellungenStandorte;
