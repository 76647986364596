import React, { useState, useEffect } from "react";
import { db, auth } from "../config/firebase-config";
import {
  collection,
  doc,
  addDoc,
  setDoc,
  getDoc,
  where,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
  limit,
} from "firebase/firestore";

import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const Protokoll = (props) => {
  const [protokoll, setProtokoll] = useState([]);
  const [protokollFilter, setProtokollFilter] = useState([]);

  const protokollRef = collection(db, "protokoll");

  useEffect(() => {
    props.setSearchString("");
  }, []);

  useEffect(() => {
    //   // console.log("useEffect aufgeufen (Protokoll.js");
    //   const queryProtokoll = query(
    //     protokollRef,
    //     orderBy("timestamp", "desc"),
    //     limit(500)
    //   );

    //   const unsubscribe = onSnapshot(queryProtokoll, (snapshot) => {
    //     let protokoll = [];
    //     snapshot.forEach((doc) => {
    //       protokoll.push({ ...doc.data(), id: doc.id });
    //     });

    //     setProtokoll(props.protokoll);
    //     // console.log("Protokoll: ", protokoll);
    //   });
    //   props.setSearchString("");

    //   return () => {
    //     unsubscribe();
    //   };
    setProtokoll(props.protokoll);
  }, [props.protokoll]);

  useEffect(() => {
    const filterProtokoll = (item) => {
      return (
        item.kennung.toLowerCase().includes(props.searchString.toLowerCase()) ||
        (item.kennungAlt &&
          item.kennungAlt
            .toLowerCase()
            .includes(props.searchString.toLowerCase())) ||
        item.fahrzeug
          .toLowerCase()
          .includes(props.searchString.toLowerCase()) ||
        item.bemerkung
          .toLowerCase()
          .includes(props.searchString.toLowerCase()) ||
        item.standort.toLowerCase().includes(props.searchString.toLowerCase())
      );
    };

    let protokollTmp = [];

    // console.log("Filter im Protokoll aufgerufen ANFANG!");
    // Filtern der Fahrzeuge mit searchString
    if (props.searchString !== "") {
      // console.log("Filter im Protokoll aufgerufen! SCHLEIFE");
      protokollTmp = protokoll.filter(filterProtokoll);
    }

    if (props.searchString.length > 0) {
      setProtokollFilter(protokollTmp);
    } else {
      setProtokollFilter(protokoll);
    }
  }, [props.searchString, protokoll]);

  const statusToText = (status) => {
    let result = "";

    switch (status) {
      case "aussserdienst":
      case "ausserdienst":
        result = "Nicht Dienstfähig";
        break;
      case "einsatzbereit":
        result = "Regelbedarf";
        break;
      case "desinfektion":
        result = "Desinfektion";
        break;
      case "technischeReserve":
        result = "Reservefahrzeuge";
        break;
      case "taktischeReserve":
        result = "Spitzenbedarf";
        break;
      case "sonderbedarf":
        result = "Sonderbedarf";
        break;
      case "fuehrung":
        result = "Führung";
        break;
      default:
        result = "Fehler";
    }

    return result;
  };

  return (
    <Container>
      <Card className="statusColumn">
        <Card.Header style={{ backgroundColor: "lightgray" }}>
          Protokoll
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover className="protokollTable">
            <thead>
              <tr>
                <th className="col-2">Datum, Uhrzeit</th>
                <th className="col-1">Fahrzeug</th>
                <th className="col-2">Status</th>
                <th className="col-2">Kennung</th>
                <th className="col-1">Standort</th>
                <th className="col-4">Bemerkung</th>
              </tr>
            </thead>
            <tbody>
              {protokollFilter.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.datum.replace("->", "")} Uhr</td>
                    <td>{item.fahrzeug}</td>
                    <td>{statusToText(item.status)}</td>
                    <td>
                      Aktuell: {item.kennung === "" ? <>Ohne</> : item.kennung}
                      {"kennungAlt" in item ? (
                        <>
                          <br />
                          <span className="protokollAlt">
                            Vorher:{" "}
                            {item.kennungAlt === "" ? (
                              <>Ohne</>
                            ) : (
                              item.kennungAlt
                            )}
                          </span>{" "}
                        </>
                      ) : undefined}
                    </td>
                    <td>{item.standort}</td>
                    <td>{item.bemerkung}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="statusFooter">
          {protokollFilter.length === 0 ? "Keine Einträge" : undefined}
          {protokollFilter.length === 1 ? "1 Eintrag" : undefined}
          {protokollFilter.length > 1
            ? protokollFilter.length + " Einträge"
            : undefined}
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default Protokoll;
