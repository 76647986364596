import React from "react";
import { auth } from "../config/firebase-config";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import EinstellungColumn from "./EinstellungColumn";
import EinstellungenStandorte from "./EinstellungenStandorte";
import EinstellungenFunkkennungen from "./EinstellungenFunkkennungen";
import EinstellungenFahrzeuge from "./EinstellungenFahrzeuge";

const Einstellungen = () => {
  if (
    auth.currentUser.uid === "2aIshEOZfDN86uezlDQATJK2qHv2" ||
    auth.currentUser.uid === "REpv0gLVbxcaBWAzqCDxA2lvNBF2" ||
    auth.currentUser.uid === "MSv3hmfWx3SqBrZq4oJNahs4Ozp2" ||
    auth.currentUser.uid === "4oW3L41cBHY4jq4t1VeUMLTO9Jj2" ||
    auth.currentUser.uid === "ncChh6vZsoSgLmYrhaNRRyx5uca2" ||
    auth.currentUser.uid === "nVC9thVgJfh9sdnb5AocU0a7SLv1" ||
    auth.currentUser.uid === "8SnVqcFhiUeDXknm48ZAB4SO4cH3"
  ) {
    return (
      <Container>
        <Row>
          <Col xs={6}>
            <EinstellungenFahrzeuge title="Fahrzeuge" bg="#dedede" color="" />
          </Col>
          <Col xs={3}>
            <EinstellungenFunkkennungen
              title="Funkkennungen"
              bg="#dedede"
              color=""
            />
          </Col>
          <Col xs={3}>
            <EinstellungenStandorte title="Standorte" bg="#dedede" color="" />
          </Col>
        </Row>
      </Container>
    );
  } else {
    return <Container>Für dich gibt es hier nichts zu sehen :)</Container>;
  }
};

export default Einstellungen;
