import React from "react";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

const Search = (props) => {
  return (
    <>
      <InputGroup size="sm" className="mb-6">
        <Form.Control
          placeholder="Suche / Filter"
          aria-label="Suche"
          aria-describedby="basic-addon1"
          value={props.searchString}
          onChange={(e) => props.setSearchString(e.target.value)}
        />
        <Button
          variant="warning"
          id="button-addon2"
          onClick={() => {
            props.setSearchString("");
          }}
        >
          X
        </Button>
      </InputGroup>
    </>
  );
};

export default Search;
