import React from "react";

import { Container } from "react-bootstrap";

import Menu from "./Menu";

const Header = (props) => {
  return (
    <>
      <div className="header">
        <div className="container">
          <div className="title">
            <h1>Rettungsdienst Bremen</h1>
            <p>Fahrzeugverwaltung (Version 0.1.20)</p>
          </div>
          <div className="logo"></div>
        </div>
      </div>
      <Menu
        page={props.page}
        changePage={props.changePage}
        searchString={props.searchString}
        setSearchString={props.setSearchString}
      />
    </>
  );
};

export default Header;
