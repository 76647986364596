import React from "react";

import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-date-picker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import MangelColumn from "./MangelColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faCoffee,
  faFileArrowDown,
} from "@fortawesome/free-solid-svg-icons";

let maengel1 = [
  {
    date: "01.01.2024",
    melder: "Malte Helfrich",
    prio: 1,
    text: "Mangel 1",
  },
  {
    date: "01.01.2024",
    melder: "Malte Helfrich",
    prio: 2,
    text: "Mangel 2",
  },
  {
    date: "01.01.2024",
    melder: "Malte Helfrich",
    prio: 3,
    text: "Mangel 3",
  },
  {
    date: "01.01.2024",
    melder: "Malte Helfrich",
    prio: 4,
    text: "Mangel 4",
  },
];

let maengel2 = [
  {
    date: "01.01.2024",
    melder: "Malte Helfrich",
    prio: 3,
    text: "Mangel 1",
  },
  {
    date: "01.01.2024",
    melder: "Malte Helfrich",
    prio: 3,
    text: "Mangel 2",
  },
];

let maengel3 = [
  {
    date: "01.01.2024",
    melder: "Malte Helfrich",
    prio: 4,
    text: "Mangel 1",
  },
  {
    date: "01.01.2024",
    melder: "Malte Helfrich",
    prio: 4,
    text: "Mangel 2",
  },
  {
    date: "01.01.2024",
    melder: "Malte Helfrich",
    prio: 4,
    text: "Mangel 3",
  },
  {
    date: "01.01.2024",
    melder: "Malte Helfrich",
    prio: 4,
    text: "Mangel 4",
  },
];

const Fahrzeug = () => {
  return (
    <>
      <Container>
        <Card className="statusColumn">
          <Card.Header style={{ backgroundColor: "lightgray" }}>
            Fahrzeugdaten: B-8956 | RTW
          </Card.Header>
          <Card.Body>
            <Container>
              <Form>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Status</Form.Label>
                      <Form.Select name="modalstatus">
                        <option value="">Einsatzbereit</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Funkkennung</Form.Label>
                      <Form.Select name="modalfunkkennung">
                        <option value="">Keine Funkkennung</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Standort</Form.Label>
                      <Form.Select>
                        <option value="">Kein Standort ausgewählt</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Letzte Grunddesinfektion</Form.Label>
                      <div className="datepicker">
                        <DatePicker />
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="inputKilomter">
                        Kilometerstand
                      </Form.Label>
                      <Form.Control type="text" id="inputKilomter" />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Bemerkung</Form.Label>
                      <Form.Control as="textarea" rows={1} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Row>
                      <Button
                        variant="outline-secondary"
                        style={{ marginBottom: "10px" }}
                      >
                        <FontAwesomeIcon icon={faClipboard} /> Fahrzeughistorie
                        anschauen
                      </Button>
                    </Row>
                    <Row>
                      <Button
                        variant="outline-secondary"
                        style={{ marginBottom: "10px" }}
                      >
                        <FontAwesomeIcon icon={faFileArrowDown} />{" "}
                        Fahrzeughistorie herunterladen
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Container>
            <Container>
              <Row>
                <Col>
                  <MangelColumn
                    title="Vorhandene Mängel"
                    bg="#dedede"
                    maengel={maengel1}
                  />
                </Col>
                <Col>
                  <MangelColumn
                    title="Zurückgestellte Mängel"
                    bg="#dedede"
                    maengel={maengel2}
                  />
                </Col>
                <Col>
                  <MangelColumn
                    title="Erledigte Mängel"
                    bg="#dedede"
                    maengel={maengel3}
                  />
                </Col>
              </Row>
            </Container>
          </Card.Body>
          <Card.Footer style={{ textAlign: "right" }}>
            <Button style={{ marginRight: "5px" }} variant="secondary">
              Schließen
            </Button>
            <Button variant="primary" disabled>
              Speichern
            </Button>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
};

export default Fahrzeug;
