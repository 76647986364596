import React, { useState, useEffect } from 'react';
import { db, auth } from "../config/firebase-config";
import {
  collection,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  where,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";

import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { MdDeleteForever, MdAddCircle } from 'react-icons/md';

const EinstellungenFunkkennungen = (props) => {
  const [funkkennungen, setFunkkennungen] = useState([]);
  const [funkkennungNeu, setFunkkennungNeu] = useState('');
  const [modal, setModal] = useState(false);

  const funkkennungenRef = collection(db, "funkkennungen");

  useEffect( () => {
    const queryFunkkennungen = query(
      funkkennungenRef,
      orderBy("bezeichnung")
    );

    const unsubscribe = onSnapshot(queryFunkkennungen, (snapshot) => {
      let funkkennungen = [];
      snapshot.forEach( (doc) => {
        funkkennungen.push({...doc.data(), id: doc.id});
      });
      setFunkkennungen(funkkennungen);
    });

    return () => unsubscribe();
  }, []);

  const handleClose = () => {
    setModal(false);
    setFunkkennungNeu('');
  }

  const handleOpen = () => {
    setModal(true);
  }

  const handleSave = () => {
    const data = {
      bezeichnung: funkkennungNeu,
      inUse: false
    };

    addDoc(funkkennungenRef, data)
    .then(docRef => {
        // console.log("Document Field has been updated successfully");
        handleClose();
    })
    .catch(error => {
        console.log(error);
    })
  }

  const handleDelete = (id) => {
    // console.log('delete clicked', id);
    const docRef = doc(db, 'funkkennungen', id);
    deleteDoc(docRef)
    .then( () => {
      // console.log('Eintrag gelöscht');
    })
    .catch(error => {
      console.log('error');
    })
  };

  let color = 'black';
  if (props.color !== undefined) color = props.color;
  return (
    <>
    <Card 
      className="statusColumn"
    >
      <Card.Header style={{backgroundColor: props.bg, color: color, textAlign: 'center'}}>
        <div className="settingHeader">
          <div className="settingTitle">{props.title}</div>
          <div><Button variant="success" className="btn-add" onClick={handleOpen}><MdAddCircle /></Button></div>
        </div>
      </Card.Header>

      <Card.Body> 
        <Table striped bordered hover>
            <thead>
              <tr>
                <th>Bezeichnung</th>
                <th>Verwendet</th>
                <th>Aktion</th>
              </tr>
            </thead>
            <tbody>
              {
                funkkennungen.map( funkkennung => {
                  return (
                    <tr key={funkkennung.id}>
                      <td>{funkkennung.bezeichnung}</td>
                      <td>{funkkennung.inUse ? 'JA' : 'NEIN'}</td>
                      <td>
                        <MdDeleteForever className="btn-delete" onClick={() => {handleDelete(funkkennung.id)}}/>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
      </Card.Body>
    </Card>

    <Modal show={modal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Funkkennung hinzufügen</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Bezeichnung</Form.Label>
          <Form.Control 
            type="text"
            value={funkkennungNeu}  
            onChange={ (event) => setFunkkennungNeu(event.target.value)}
            placeholder="Bezeichnung eingeben"
          />
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Speichern
          </Button>
        </Modal.Footer>
    </Modal>
    </>
  );
}

export default EinstellungenFunkkennungen;