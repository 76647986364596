import React from "react";
import moment from "moment";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const MangelBox = (props) => {
  let prio = "prio1";
  let prioText = "Hohe Priorität";

  switch (props.prio) {
    case 1:
      prio = "prio1";
      prioText = "Hohe Priorität";
      break;
    case 2:
      prio = "prio2";
      prioText = "Mittlere Priorität";
      break;
    case 3:
      prio = "prio3";
      prioText = "Niedriege Priorität";
      break;
    default:
      prio = "prio4";
      prioText = "Ohne Priorität";
      break;
  }

  return (
    <div className={"mangel " + prio} draggable>
      <div className="mangelHeader">
        <div className="mangelInfo">
          {props.date} | {props.melder}
        </div>
        <div className="mangelPrio">{prioText}</div>
      </div>
      Mangel
    </div>
  );
};

export default MangelBox;
