import React from 'react'

const Copyright = () => {
  return (
    <div className="copyright">
      made by MOIN MOIN media
    </div>
  )
}

export default Copyright;